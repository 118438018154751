.content {
    padding: 20px;
  }
  .button {
    display: inline-block;
    padding: 10px 20px;
    background: #1F2373;
    color: white;
    border-radius: 5px;
    text-transform: uppercase;
  }
  
.carousel-container {
  position: absolute;
  top: 6.5%; /* Ajuste para manter o carrossel centrado verticalmente */
  left: 6.5%; /* Mantém uma margem esquerda constante */
  right: 6.5%; /* Mantém uma margem direita constante */
  bottom: 6%; /* Mantém uma margem inferior constante */
  overflow: hidden;
}

.carousel-image {
  width: 100%; /* Preenche a largura do container */
  height: 98%; /* Preenche a altura do container */
}
/* Estilos Gerais */
.home-section {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  min-height: 50vh; /* Manter a altura mínima */
  background-color: #1F248C;
  padding: 0 10% 5%; /* Aumentar o padding inferior */
}


.text-content {
  color: #fff;
  text-align: left;
  max-width: 50%;
  margin-left: 2%;
  margin-bottom: 0;
}

.text-content h1 {
  font-size: 60px;
  margin-bottom: 10px;
  font-weight: bold;
  font-family: var(--font-h1);
  margin-top: 0;
}

.text-content p {
  font-size: 19px;
  font-family: var(--font-h1);
  margin-top: 0;
}

.cta-button {
  padding: 1.2em 2.5em;
  background: linear-gradient(135deg, #926ed4, #621be3);
  color: #FFFFFF;
  border: none;
  border-radius: 2.5em;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  font-weight: bold;
}

/* Media Queries */
@media (min-width: 1024px) {
  .text-content {
    margin-left: 2%;
  }

  .text-content h1 {
    margin-left: 20%;
  }

  .text-content p {
    margin-left: 20%;
  }

  .cta-button {
    margin-left: 20%;
    margin-bottom: 50px; /* Reduzido de 400px para 50px */
    margin-top: 50px;
  }
}

@media (max-width: 898px) {
  .home-section {
    flex-direction: column;
    justify-content: center;
    padding: 5%;
  }

  .text-content {
    max-width: 100%;
    margin: 10% 0;
    text-align: center;
  }

  .text-content h1 {
    font-size: 30px;
    margin: 0 0 10%;
    margin-top: -5%;
  }

  .text-content p {
    font-size: 18px;
    margin-bottom: 10%;
  }

  .cta-button {
    margin: 10px auto 10%;
    display: block;
    width: auto;
    margin-bottom: -5%;
  }
}

/* Estrutura Base */
.PhoneDisplay {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.phone-frame {
  position: relative;
  width: 100%;
  max-width: 350px; /* Largura máxima para telas maiores */
  height: 680px;
  overflow: hidden;
  margin: auto;
  margin-top: 5%;
}

.phone-image {
  width: 100%;
  height: 100%;
  display: block;
}

.carousel-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/* Responsividade para telas menores que 768px */
@media (max-width: 768px) {
  .PhoneDisplay {
    order: -1; /* Coloca o PhoneDisplay acima do texto em coluna */
  }

  .phone-frame {
    max-width: 300px; /* Largura reduzida para telas pequenas */
    height: 550px; /* Altura ajustada para telas pequenas */
    margin-top: 10%;
  }
}

/* Responsividade para telas até 1024px */
@media (max-width: 1024px) and (min-width: 769px) {

  .phone-frame {
    max-width: 320px; /* Largura ajustada para telas médias */
    height: 600px; /* Altura ajustada para telas médias */
  }
}

/* Responsividade para telas até 1900px */
@media (max-width: 1900px) and (min-width: 1025px) {
  .phone-frame {
    max-width: 340px; /* Largura ajustada para telas grandes */
    height: 650px; /* Altura ajustada para telas grandes */
    margin-top: -40%;
  }
}

/* Responsividade para telas maiores que 1900px */
@media (min-width: 1901px) {
  .phone-frame {
    max-width: 350px; /* Mantém a largura máxima */
    height: 680px; /* Mantém a altura para telas extra grandes */
    margin-top: -40%;
  }
}

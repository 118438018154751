.alinhamento {
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
    padding-block-end: 5%;
    padding-top: 5%;
    text-align: center;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;
    color: aliceblue;
    background-color: #1F248C;
}

.pncd {
    width: 100%;
    text-align: center;
  }
  
  .pncd img {
    display: block; /* Torna a imagem um elemento de bloco */
    max-width: 100%; /* Garante que a imagem seja responsiva */
    height: auto; /* Mantém a proporção da imagem */
    margin: auto; /* Centraliza a imagem horizontalmente */
  }

  .nossoAplicativo{
    margin-bottom: 15%;
  }

.guia {
    margin-top: -15%;
}

.label {
    margin-bottom: -10%;
}

.app {
    margin-top: 10%;
}

.iconeWifi{
  margin-top: 15%;
}

.iconeMsg{
  margin-top: 20%;
}

.iconeRelogio{
margin-top: -20%;
}

.utilizacaoDados {
    margin-top: -10%;
}

.textoCentral {
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
    padding-block-end: 10%;
    padding-top: 5%;
    text-align: left;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;
    color: #000;
    background-color: #ffff;
}

.segundaparte {
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    padding-block-end: 10%;
    padding-top: 5%;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;
    background-color: #f2f1f0;
}

@media (max-width: 1024px) {
    .pncd {
        width: 100%;
        padding-left: 0%;
        padding-right: 10%;
    }

    .segundaparte {
      padding-left: 10%;
      padding-right: 10%;
  }
  .app{
    margin-bottom: 0%;
  }

  .iconeMsg{
    margin-top: 15%;
  }
  
  .iconeWifi{
    margin-top: 15%;
  }

  .iconeRelogio{
    margin-top: 5%;
  }
}
.alinhamentoEsquerda {
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
    padding-block-end: 10%;
    padding-top: 5%;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;
    background-color: #f2f1f0;
margin-top: -10%;
}

@media (max-width: 1024px) {
    .alinhamentoEsquerda {
        justify-content: space-between;
        padding-left: 10%;
        padding-right: 10%;
        padding-block-end: 10%;
        padding-top: 5%;
        text-align: center;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.5;
        background-color: #f2f1f0;
    
    }
}
header {
  display: flex;
  flex-direction: row; /* Muda para row para alinhar logo e menu horizontalmente */
  justify-content: space-between; /* Espaça a logo e o menu-button nas extremidades */
  align-items: center; /* Alinha verticalmente ao centro */
  background: #1F248C;
  color: #333;
  position: relative;
  width: 100%;
  padding: 10px 0;
}

.header-main {
  display: flex;
  justify-content: flex-start; /* Alinha a logo à esquerda */
  align-items: center;
  width: 100%;
  padding: 10px 30px;
}

.menu-button {
  display: none; /* Fica oculto em telas maiores */
  background: none;
  border: none;
  color: #fff;
  font-size: 2rem;
  cursor: pointer;
  position: absolute; /* Posiciona absolutamente dentro do header */
  right: 30px; /* Ajusta a distância da direita */
  top: 30px; /* Ajusta a distância do topo */
}

header img {
  height: 180px;
  width: auto;
  margin-left: 180px; /* Margem para não colar na borda */
}

nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-right: 100px;
}

nav a {
  color: #ffffff;
  text-decoration: none;
  padding: 10px 15px;
  text-align: center;
  font-weight: bold;
  display: inline-block;
}

@media (max-width: 1041px) {
  header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .header-main {
    justify-content: space-between; /* Garante que logo e menu estão nas extremidades */
  }

  nav {
    flex-direction: row;
    justify-content: flex-start; /* Ajusta navegação para o início */
    padding-left: 0;
    margin-left: 20px; /* Espaço para não colar na logo */
  }

  .header-main {
    justify-content: space-between; /* Ajusta para que logo e menu-button fiquem separados */
    padding: 0;
  }

  .menu-button {
    display: none;
  }

  header img {
    margin-left: 10px; /* Margem para não colar na borda */
  }
}

@media (max-width: 768px) {
  .menu-button {
    display: block; /* Mostra o menu hambúrguer em telas pequenas */
  }

  .sidebar {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    background: #1F248C;
    width: 100%;
    z-index: 10;
  }

  .sidebar.open {
    display: flex;
  }

  .header-main {
    justify-content: space-between; /* Ajusta para que logo e menu-button fiquem separados */
    padding: 0;
  }

  header img {
    margin-left: 10px; /* Margem para não colar na borda */
  }

  nav {
    display: none; /* Esconde a nav principal em telas pequenas */
  }
}
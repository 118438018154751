.alinhamentoCentro {
  text-align: center;
  font-family: var(--font-body);
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  padding: 5%;
  margin-top: -5%;
}

.text{
  text-align: justify;
}

.colunas3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Ajuste para 2 colunas iguais */
  grid-gap: 20px;
  color: #000;
  align-items: center; /* Centraliza verticalmente o conteúdo das colunas */
  justify-content: center;
  margin-top: 10%; /* Centraliza horizontalmente o conteúdo das colunas */
}

.app {
  grid-column: 2 / 3; /* Faz o .app ocupar somente a coluna do meio */
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  margin-top: 0; /* Remove margin-top desnecessária */
}
.tituloFinal {
  text-align: justify;
}

/* Estilos para telas menores ou iguais a 1024px */
@media screen and (max-width: 1024px) {
  .colunas3 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "info1"
      "info2"
      "app"
      "info3"
      "info4";
  }

  .text{
    text-align: left;
  }

  .column {
    margin-bottom: -19%;
  }

  .column:nth-child(1) {
    grid-area: info1;
  }

  .app {
    grid-area: app;
    margin-top: 0;
  }

  .column:nth-child(3) {
    grid-area: info3;
  }

  .tituloFinal {
    margin-top: 10%;
  }

  .gps {
    margin-top: -40%;
  }

  .aprimorando{
    margin-top: 0;
  }

  .alinhamentoCentro {
    margin-top: 5%;
  }
}

/* Global Styles - Consolidado */
body, html {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background: #f2f1f0;
  overflow-x: hidden;  /* Previne rolagem horizontal indesejada */
}

/* Header Styles */
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1F248C;
  padding: 10px 30px;
  color: #333;
  width: 100%;
}

header img {
  height: 80px;
  width: auto;  /* Garante que a largura seja ajustada automaticamente */
}

/* Link Styles */
a {
  color: #ffffff;
  text-decoration: none;
  margin: 0 15px;
  font-weight: bold;
}

/* Typography - Ajuste para responsividade */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.2;
}

@media (max-width: 1200px) {
  h1 { font-size: 2.5em; }
  /* Outros estilos de h2-h6 como mostrado anteriormente */
}

@media (max-width: 768px) {
  h1 { font-size: 2em; margin: 0.3em 0; }
  /* Outros estilos de h2-h6 como mostrado anteriormente */
}

/* Botões e Links Responsivos */
.button, .App-link, button {
  display: inline-block;
  padding: 10px 20px;
  background: #1F2373;
  color: white;
  border-radius: 5px;
  text-transform: uppercase;
}

/* Correção para ícones */
.icone {
  width: 90px;  /* Corrigido para remover as aspas */
  height: 90px; /* Corrigido para remover as aspas */
  display: block; /* Alterado de 'center' para 'block' */
  margin: 0 auto; /* Centraliza se necessário */
}

/* Responsividade para imagens */
img {
  max-width: 100%;
  height: auto;
  display: block; /* Remove espaços extras abaixo das imagens */
}

html {
  scroll-behavior: smooth;
}
.vantagens {
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
    padding-block-end: 10%;
    padding-top: 5%;
    text-align: left;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;
    background-color: #f2f1f0;
}


footer {
  text-align: center;
  padding: 20px;
  background: #1f248c;
  color: #fff;
}

.footer-info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.footer-info p {
  margin: 0; /* Ajuste para margem */
}

/* Estilizando os links para ficarem com texto branco */
a.whatsapp-link,
a.email-link {
  color: #fff;
  text-decoration: none; /* Remove o sublinhado dos links */
}

a.whatsapp-link:hover,
a.email-link:hover {
  text-decoration: underline;
}

/* Estilo para ícones */
.icon {
  font-size: 24px; /* Aumentando o tamanho dos ícones */
  vertical-align: middle; /* Alinha verticalmente com o texto */
}

/* Responsivo para telas menores que 1024px */
@media (max-width: 1024px) {
  .footer-info {
    flex-direction: column; /* Muda a direção para coluna */
    gap: 10px; /* Reduz o gap para adequar ao layout vertical */
  }

  .icon {
    font-size: 20px; /* Tamanho menor para ícones em telas pequenas */
  }
}

@media (max-width: 768px) {
  .icon {
    font-size: 18px; /* Tamanho ainda menor para ícones em telas muito pequenas */
  }
}

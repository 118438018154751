.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #1F248C; /* Cor de fundo azul */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  z-index: 1000;
}

.scroll-to-top:hover {
  background-color: #0056b3; /* Cor mais escura ao passar o mouse */
}

/* Media query para telas de até 1024px */
@media (max-width: 1024px) {
  .scroll-to-top {
      padding: 8px 12px; /* Reduz o tamanho do padding */
      right: 10px; /* Posiciona o botão mais à direita */
      font-size: 14px; /* Reduz o tamanho da fonte */
  }
}

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background: #ffff;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
  margin: 0.5em 0;
  font-size: 45px; /* O tamanho padrão para títulos, que será ajustado nas media queries */
}

.alinhamentoCentro {
  text-align: center;
  font-family: var(--font-body);
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  padding: 5% 5%;
}

img.wp-smiley, img.emoji {
  display: inline !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 0.07em !important;
  vertical-align: -0.1em !important;
  background: none !important;
  padding: 0 !important;
}

.imgTam {
  max-width: 100%;
  height: auto;
  max-height: 988px;
  margin: 0 auto;
}

.colunas2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-gap: 20px;
  color: #000;
}

.column {
  padding: 20px;
  color: #333;
  margin-top: -35%;
}

.panel{
  margin-bottom: 15%;
}

.icone{
  margin-top: 20%;
}

.notebook-frame {
  position: relative;
  width: 100%;
  height: 1200px;
  overflow: hidden;
  margin: auto;
  margin-top: -20%;
}

.titulo {
  font-size: 45px;
  font-family: 'Montserrat', sans-serif;
  margin: 0.5em 0;
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
  margin: 0;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: -0.05em;
  margin-bottom: 0;
  transition: color 0.2s;
  padding: 0.5em 0;
}

.iconeCartao {
  margin-top: 20%;
}

/* Responsividade para telas menores que 768px */
@media (max-width: 768px) {
  h1, h2, h3, h4, h5, h6 {
    font-size: 32px;
  }

  .colunas2 {
    grid-template-columns: 1fr;
  }

  .notebook-frame {
    height: 450px;
  }

  .column, .icone, .iconeCartao {
    margin-top: 10%;
  }
}

@media screen and (max-width:769px) {
  .colunas2 {
    grid-template-columns: 1fr; /* Define todas as colunas para ocuparem uma linha cada */
    grid-template-areas:
      "info1"
      "info2"
      "app"
      "info3"
      "info4";
  }
  .notebook-frame {
    position: relative;
    width: 360px;
    max-width: 360px;
    height: 360px;
    overflow: hidden;
    margin: auto;
    margin-top: -20%;
    margin-bottom: -30%;
  }

  .column {
    padding: 20px;
    color: #333;
    margin-top: -10%;
  }
  .home{
    margin-top: 30%;
  }

  .panel{
    margin-top:-10%;
  }

  .iconeMira{
    margin-top: 5%;
  }

  .iconeCartao{
    margin-top: 10%;
  }

  .iconeRel{
    margin-top: 5%;
  }
}


/* Responsividade para telas até 1024px */
@media screen and (min-width:1024px) {
  .colunas2 {
    grid-template-columns: 1fr; /* Define todas as colunas para ocuparem uma linha cada */
    grid-template-areas:
      "info1"
      "info2"
      "app"
      "info3"
      "info4";
  }
  .notebook-frame {
    position: relative;
    max-width: 1000px;
    width: 1000px;
    height: 1000px;
    overflow: hidden;
    margin: auto;
    margin-top: -20%;
    margin-bottom: -30%;
  }

  .column {
    padding: 20px;
    color: #333;
    margin-top: -10%;
  }
  .home{
    margin-top: 30%;
  }

  .panel{
    margin-top:-10%;
  }

  .iconeMira{
    margin-top: 5%;
  }

  .iconeCartao{
    margin-top: 10%;
  }

  .iconeRel{
    margin-top: 5%;
  }
}

/* Responsividade para telas até 1900px */
@media (min-width: 1025px) and (max-width: 1900px) {
  .notebook-frame {
    height: 1250px;
    width: 1250px;
    max-width: 1250px;
  }

  .panel{
    margin-top: -1%;
  }

  .home{
    margin-top: 35%;
  }
}

/* Responsividade para telas maiores que 1900px */
@media (min-width: 1901px) {
  h1, h2, h3, h4, h5, h6 {
    font-size: 48px;
  }

  .panel{
    margin-top: -1%;
  }

  .notebook-frame {
    height: 1500px;
    width: 1500px;
    max-width: 1500px;
  }

  .colunas2 {
    grid-gap: 30px;
  }

  .column {
    padding: 30px;
  }

  .home{
    margin-top: 35%;
  }
}